import { Button, Divider, Grid, TextField } from "@mui/material";
import { useState } from "react";
import TooltipForDisabled from "../../shared/Tooltips";

type AddNewParamPanelProps = {
  show: boolean;
  addNewParameter: (itemName: string) => void;
  cancelAddItem: () => void;
  onShowClick: () => void;
  isReadOnly?: boolean;
};

const AddNewParamPanel = (props: AddNewParamPanelProps) => {
  const [newItemName, setNewItemName] = useState("");

  const cancelAddItem = () => {
    props.cancelAddItem();
    setNewItemName("");
  };

  const addNewParameter = () => {
    props.addNewParameter(newItemName);
    setNewItemName("");
  };

  return (
    <>
      <Grid item container direction="column">
        <Divider sx={{ margin: "20px 0" }} />
      </Grid>
      {props.show ? (
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Indtast navn"
              value={newItemName}
              onChange={(event) => setNewItemName(event.currentTarget.value)}
              margin="dense"
              multiline
              fullWidth
              variant="standard"
            />
          </Grid>
          <Grid item xs={12}>
            <Button color="secondary" variant="outlined" onClick={addNewParameter} disabled={!newItemName}>
              Tilføj
            </Button>
            <Button sx={{ margin: 1 }} color="secondary" variant="outlined" onClick={cancelAddItem}>
              Nulstil
            </Button>
          </Grid>
        </Grid>
      ) : (
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <TooltipForDisabled title="Godkendt rapport kan ikke ændres">
              <Button
                sx={{ marginBottom: 2 }}
                color="secondary"
                variant="outlined"
                onClick={props.onShowClick}
                disabled={props.isReadOnly}
              >
                Tilføj ny
              </Button>
            </TooltipForDisabled>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default AddNewParamPanel;
