import React, { useEffect, useState } from "react";
import {
  AppBar,
  Toolbar,
  Grid,
  Typography,
  MenuItem,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  IconButton,
  Button,
  useMediaQuery,
  useTheme,
  LinearProgress,
  Link,
  Divider,
  styled,
  Box,
} from "@mui/material";
import ElevationScroll from "./ElevationScroll";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import GeneralDialog from "./GeneralDialog";
import MenuIcon from "@mui/icons-material/Menu";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { ArrowBack } from "@mui/icons-material";
import { useUser } from "../common/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import MenuBlocks from "../pages/components/MenuBlocks";
import { useUserActions } from "../user/userActions";
import { UserRole } from "../interfaces/models";

const TitleContentStyled = styled("div")({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const GrowStyled = styled(Grow, {
  shouldForwardProp: (propName: string) => propName !== "placement",
})<{
  placement: string;
}>(({ placement }) => ({
  transformOrigin: placement === "bottom" ? "center top" : "center bottom",
}));

type AppToolBarProps = {
  handleDrawerToggle?: (() => void) | false;
  goBack?: (() => void) | false;
  children?: React.ReactNode;
  title?: string;
  buttons?: React.ReactNode;
  light?: boolean;
  logo?: boolean;
  loading?: boolean;
};

const AppToolBar = (props: AppToolBarProps) => {
  const { light, logo, loading } = props;
  const [openLogout, setOpenLogoutDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const theme = useTheme();
  const [title, setTitle] = useState("");
  const user = useUser();
  const userActions = useUserActions();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const roleTitle = (user?.role && MenuBlocks[user.role]?.find((x) => x.path === location.pathname)?.title) || "";
    setTitle(props.title || roleTitle);
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.role, location.pathname, props.title]);

  const goToProfile = () => {
    setAnchorEl(null);
    navigate("/" + location.pathname.split("/")[1] + "/profile");
  };

  const openLogoutDialog = () => {
    setAnchorEl(null);
    setOpenLogoutDialog(true);
  };

  const logout = (accepted?: boolean) => {
    setOpenLogoutDialog(false);
    if (accepted) {
      userActions.logout();
    }
  };

  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const goBack = () => {
    if (user.role === UserRole.CompanyManager && location.pathname === "/admin/users") {
      navigate("/overview");
      return;
    }

    if (window.history.length <= 2) {
      navigate("/overview");
    } else {
      navigate(-1);
    }
  };

  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Box sx={{ marginTop: "64px" }}></Box>

      <ElevationScroll>
        <StyledAppBar
          color={light ? "default" : "secondary"}
          position="fixed"
          sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, left: 0, width: "100%" }}
        >
          <StyledToolbar disableGutters>
            <Grid
              direction="column"
              justifyContent="space-between"
              container
              alignItems="flex-start"
              sx={{ flexDirection: "row" }}
            >
              <Grid direction="row" justifyContent="flex-start" item container alignItems="center" xs={4} sm={6}>
                {!!props.handleDrawerToggle && (
                  <IconButton color="inherit" aria-label="open drawer" onClick={props.handleDrawerToggle} size="large">
                    <MenuIcon />
                  </IconButton>
                )}
                {!logo && (
                  <IconButton onClick={props.goBack ? props.goBack : goBack} size="large">
                    <ArrowBack sx={{ color: "white" }} />
                  </IconButton>
                )}
                {logo && (
                  <Link
                    variant="body2"
                    href={process.env.REACT_APP_MYHOUSE_URL}
                    underline="none"
                    target="_blank"
                    sx={{
                      padding: "7px 15px 2px",
                      color: "inherit",
                      fontSize: "10px",
                      letterSpacing: "5px",
                      cursor: "pointer",
                      fontFamily: "'Montserrat', sans-serif",
                      "@media (min-width: 600px)": {
                        letterSpacing: "8px",
                        padding: "14px 0 12px 40px",
                        fontSize: "12px",
                      },
                    }}
                  >
                    myhouse
                  </Link>
                )}
                <TitleContentStyled>{props.children}</TitleContentStyled>
                {!props.children && (
                  <>
                    {(logo || (!isXs && !logo)) && (
                      <Typography
                        variant="body2"
                        sx={{
                          padding: "0 15px 5px",
                          fontSize: "12px",
                          "@media (min-width: 600px)": {
                            flexDirection: "row",
                            fontSize: "14px",
                            padding: "10px",
                          },
                        }}
                      >
                        {title ? title : "Service eftersyn"}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
              <Grid
                container
                item
                xs={8}
                sm={6}
                alignItems="center"
                justifyContent="flex-end"
                sx={{
                  flexWrap: "nowrap",
                  flex: 5,
                  "@media (min-width: 600px)": {
                    flexDirection: "row",
                  },
                }}
              >
                {props.buttons && <>{props.buttons}</>}
                {user.authorized && (
                  <Button
                    onClick={openMenu}
                    sx={{
                      color: "inherit",
                      padding: "12px 20px",
                    }}
                  >
                    {isXs ? (
                      <PersonOutlineIcon />
                    ) : (
                      <Typography variant="body2" color="inherit" sx={{ whiteSpace: "nowrap" }}>
                        {user ? user.firstName + " " + user.lastName : "Menu"}
                      </Typography>
                    )}

                    <ArrowDropDownIcon color="inherit" />
                  </Button>
                )}
              </Grid>
            </Grid>
          </StyledToolbar>
          {loading && <LinearProgress color="secondary" />}
        </StyledAppBar>
      </ElevationScroll>
      <GeneralDialog
        open={openLogout}
        dialogTitle={"Ønsker du at logge af?"}
        dialogText={"Husk at gemme eventuelle ændringer inden."}
        handleClose={logout}
      />
      {user.authorized && (
        <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          role={undefined}
          transition
          disablePortal
          sx={{ zIndex: 9999 }}
        >
          {({ TransitionProps, placement }) => (
            <GrowStyled {...TransitionProps} placement={placement}>
              <Paper>
                <ClickAwayListener onClickAway={handleMenuClose}>
                  <MenuList autoFocusItem={Boolean(anchorEl)}>
                    {!location.pathname.includes("/profile") && <MenuItem onClick={goToProfile}>Profil</MenuItem>}

                    {!location.pathname.includes("/admin") && (
                      <MenuItem
                        onClick={() => {
                          navigate(user.role === UserRole.CompanyManager ? "/admin" : "/inspector/admin");
                        }}
                      >
                        Admin panel
                      </MenuItem>
                    )}
                    {user.role === UserRole.CompanyManager && !location.pathname.includes("/inspector") && (
                      <MenuItem
                        onClick={() => {
                          navigate("/overview");
                        }}
                      >
                        Oversigt
                      </MenuItem>
                    )}
                    <Divider />
                    <MenuItem onClick={openLogoutDialog}>Log af</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </GrowStyled>
          )}
        </Popper>
      )}
    </>
  );
};

export default AppToolBar;

const StyledAppBar = styled(AppBar)({
  colorDefault: {
    background: "#fafafa",
  },
});

const StyledToolbar = styled(Toolbar)({
  regular: {
    minHeight: 0,
  },
});
