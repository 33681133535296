import React, { useEffect, useRef, useState } from "react";
import { Button, CircularProgress, Grid, IconButton, styled } from "@mui/material";
import { NIL, v4 as uuidv4 } from "uuid";
import { PictureRef } from "../interfaces/models";
import { PhotoCamera } from "@mui/icons-material";
import imageCompression from "browser-image-compression";

export const GridStyled = styled(Grid)(() => ({
  width: "100px",
  height: "80px",
  borderRadius: "2px",
  borderStyle: "dotted",
  borderWidth: "1px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "3px",
}));

type ChangePictureButtonProps = {
  objectId?: string;
  itemId?: string;
  getPicture: (picture: PictureRef) => void;
  noPicture?: boolean;
  buttonText?: string;
  simulateClick?: boolean;
  loading?: boolean;
};

const ChangePictureButton = (props: ChangePictureButtonProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [load, setLoad] = useState(false);
  const [clicked, setClicked] = useState(false);

  useEffect(() => {
    if (props.simulateClick) {
      showFileUpload();
    }
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showFileUpload = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
      setClicked(true);
    }
  };

  const capture = (imageData: string) => {
    if (imageData) {
      const image: PictureRef = {
        id: uuidv4(),
        objectId: props.objectId || NIL,
        itemId: props.itemId || NIL,
        url: "",
        data: imageData,
        featureId: NIL,
      };
      props.getPicture(image);
    }
  };

  const onFilesAdded = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files?.length) {
      setLoad(true);
      const file = event.target.files[0];
      let fileResult = file;

      try {
        if (file.size > 350000) {
          //compress images which are larger than 0.350 MB
          const options = {
            maxSizeMB: 0.35, // Maximum size of compressed image in MB
            initialQuality: 0.9, // Initial quality value between 0 and 1
            useWebWorker: true,
          };

          fileResult = await imageCompression(file, options);
        }
      } catch (error) {
        console.error("Error compressing the image:", error);
      }

      try {
        const reader = new FileReader();
        reader.readAsDataURL(fileResult);
        reader.onload = () => {
          if (reader.result) {
            capture(reader.result as string);
            event.target.value = "";
          }
        };
        reader.onerror = (error) => console.error(error);
      } catch (error) {
        console.error("Error reading image file:", error);
      } finally {
        setTimeout(() => {
          setClicked(false);
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (clicked) {
      setLoad(props.loading || false);
    }

    if (!clicked && !props?.loading) {
      setLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.loading]);

  return (
    <>
      {props.buttonText ? (
        <Button variant="outlined" color="secondary" onClick={showFileUpload}>
          {props.buttonText}
        </Button>
      ) : (
        <GridStyled item>
          <IconButton onClick={showFileUpload} size="large">
            {load ? <CircularProgress /> : <PhotoCamera />}
          </IconButton>
        </GridStyled>
      )}
      <input
        ref={inputRef}
        style={{ display: "none" }}
        type="file"
        accept="image/*"
        multiple={false}
        onChange={onFilesAdded}
        title=""
        placeholder=""
        aria-label="Upload Picture"
      />
    </>
  );
};

export default ChangePictureButton;
