import React, { useState } from "react";
import { Modal, Button, CircularProgress, Typography, styled } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const ButtonBlock = styled("div")(() => ({
  width: "100%",
  height: 80,
  backgroundColor: "black",
}));

const ButtonContainer = styled("div")(() => ({
  display: "flex",
  height: "100%",
  alignItems: "center",
  alignSelf: "center",
  justifyContent: "space-between",
}));

const RightButton = styled(Button)(() => ({
  "&:only-child": {
    marginLeft: "auto",
  },
  color: "white",
}));

const Image = styled("img", { shouldForwardProp: (prop) => prop !== "loaded" })<{ hidden: boolean }>(({ hidden }) => ({
  maxWidth: "100%",
  maxHeight: "calc(100vh - 90px)",
  display: hidden ? "none" : "inherit",
}));

const MainContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
});

const TopBar = styled("div")({
  display: "flex",
  backgroundColor: "black",
  height: 64,
  width: "100%",
  alignItems: "center",
});

const Picture = styled("div")({
  display: "flex",
  width: "100%",
  maxHeight: "100%",
  flex: "1 auto",
  alignItems: "center",
  justifyContent: "center",
  overflow: "hidden",
});

const ImageModal = (props: {
  shown?: boolean;
  handleClose?: () => void;
  source: string | undefined;
  remove?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);

  const { source } = props;

  const imageLoaded = () => {
    setLoaded(true);
    setError(false);
  };

  return (
    <Modal open={props.shown || false} onClose={props.handleClose} sx={{ zIndex: 1300 }}>
      <MainContainer>
        <TopBar>
          <Button onClick={props.handleClose} sx={{ color: "white" }}>
            <ArrowBack style={{ color: "white" }} />
            Tilbage
          </Button>
        </TopBar>
        <Picture>
          {props.source && !error ? (
            <>
              <Image src={source} alt="" hidden={!loaded} onLoad={imageLoaded} onError={() => setError(true)} />
              {!loaded && !error && <CircularProgress />}
            </>
          ) : (
            <Typography variant="h4">Indlæsningsfejl</Typography>
          )}
        </Picture>
        <ButtonBlock>
          <ButtonContainer>
            {!!props.remove && (
              <RightButton onClick={props.remove}>
                <DeleteForeverOutlinedIcon style={{ color: "white" }} />
                Slet
              </RightButton>
            )}
          </ButtonContainer>
        </ButtonBlock>
      </MainContainer>
    </Modal>
  );
};

export default ImageModal;
