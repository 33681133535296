export const Template = {
  GET_TEMPLATE: "GET_TEMPLATE",
  UPLOAD_TEMPLATE: "UPLOAD_TEMPLATE",
  TEMPLATE_REQUEST_FAILED: "TEMPLATE_REQUEST_FAILED",
  TEMPLATE_REQUEST_SUCCEEDED: "TEMPLATE_REQUEST_SUCCEEDED",
  ADD_SECTION: "ADD_SECTION",
  REMOVE_SECTION: "REMOVE_SECTION",
  ADD_ITEM: "ADD_ITEM",
  REMOVE_ITEM: "REMOVE_ITEM",
  ADD_OPTION: "ADD_OPTION",
  CHANGE_OPTION: "CHANGE_OPTION",
  REMOVE_OPTION: "REMOVE_OPTION",
  CHANGE_SECTION: "CHANGE_SECTION",
  CHANGE_ITEM_VALUE: "CHANGE_ITEM_VALUE",
  CLEAR_TEMPLATE: "CLEAR_TEMPLATE",
  CHANGE_TEMPLATE_NAME: "CHANGE_TEMPLATE_NAME",
  SET_DEFAULT_TEMPLATE: "SET_DEFAULT_TEMPLATE",
  ADD_ITEM_OPTION: "ADD_ITEM_OPTION",
  REMOVE_ITEM_OPTION: "REMOVE_ITEM_OPTION",
  CHANGE_ITEM_OPTION: "CHANGE_ITEM_OPTION",
  ADD_GROUP: "ADD_GROUP",
  REMOVE_GROUP: "REMOVE_GROUP",
  ADD_GROUP_ITEM: "ADD_GROUP_ITEM",
};

export const Common = {
  SWITCH_PDF_MODE: "SWITCH_PDF_MODE",
  GET_HOUSE_DATA: "GET_HOUSE_DATA",
  GET_HOUSE_DATA_SUCCEEDED: "GET_HOUSE_DATA_SUCCEEDED",
  GET_LIST: "GET_LIST",
  GET_TEMPLATE_LIST_SUCCEEDED: "GET_TEMPLATE_LIST_SUCCEEDED",
  GET_TEMPLATE_LIST_FAILED: "GET_TEMPLATE_LIST_FAILED",
  CLEAR_REPORT_LIST: "CLEAR_REPORT_LIST",
  GET_REPORT_LIST_SUCCEEDED: "GET_REPORT_LIST_SUCCEEDED",
  GET_OFFER_LIST_SUCCEEDED: "GET_OFFER_LIST_SUCCEEDED",
  GET_GROUPED_REPORT_LIST_SUCCEEDED: "GET_GROUPED_REPORT_LIST_SUCCEEDED",
  GET_GROUPED_REPORT_LIST_FAILED: "GET_GROUPED_REPORT_LIST_FAILED",
  APPLY_FILTER: "APPLY_FILTER",
  APPLY_FILTER_SUCCEEDED: "APPLY_FILTER_SUCCEEDED",
  APPLY_FILTER_FAILED: "APPLY_FILTER_FAILED",
  UPDATE_REPORT_LIST: "UPDATE_REPORT_LIST",
  GOT_ERROR: "GOT_ERROR",
  CLEAR_ERROR: "CLEAR_ERROR",
  START_SIGNALR: "START_SIGNALR",
  START_SIGNALR_SUCCEEDED: "START_SIGNALR_SUCCEEDED",
  STOP_SIGNALR: "STOP_SIGNALR",
  STOP_SIGNALR_SUCCEEDED: "STOP_SIGNALR_SUCCEEDED",
  SETUP: "SETUP",
  SETUP_FAILED: "SETUP_FAILED",
};

export const Report = {
  GET_CUSTOMER_REPORT_LIST_SUCCEEDED: "GET_CUSTOMER_REPORT_LIST_SUCCEEDED",
  GET_REPORT: "GET_REPORT",
  NEED_UPDATE: "NEED_UPDATE",
  GET_LOCAL_TEMPLATE: "GET_LOCAL_TEMPLATE",
  LOCAL_TEMPLATE_NOT_FOUND: "LOCAL_TEMPLATE_NOT_FOUND",
  GET_REPORT_FAILED: "GET_REPORT_FAILED",
  GET_REPORT_SUCCEEDED: "GET_REPORT_SUCCEEDED",
  GET_REPORT_TEMPLATE: "GET_REPORT_TEMPLATE",
  GET_REPORT_TEMPLATE_SUCCEEDED: "GET_REPORT_TEMPLATE_SUCCEEDED",
  GET_REPORT_TEMPLATE_FAILED: "GET_REPORT_TEMPLATE_FAILED",
  SAVE_LOCAL_REPORT: "SAVE_LOCAL_REPORT",
  CHANGE_REPORT_VALUE: "CHANGE_REPORT_VALUE",
  UPDATE_REPORT_DATA: "UPDATE_REPORT_DATA",
  SET_REPORT_DATA: "SET_REPORT_DATA",
  ADD_REPORT_OBJECT: "ADD_REPORT_OBJECT",
  UPDATE_REPORT_OBJECTS: "UPDATE_REPORT_OBJECTS",
  CHANGE_REPORT_OBJECT: "CHANGE_REPORT_OBJECT",
  UPDATE_REPORT_PARAMETERS: "UPDATE_REPORT_PARAMETERS",
  UPLOAD_REPORT: "UPLOAD_REPORT",
  UPLOAD_REPORT_SUCCEEDED: "UPLOAD_REPORT_SUCCEEDED",
  UPLOAD_REPORT_FAILED: "UPLOAD_REPORT_FAILED",
  INIT_REPORT: "INIT_REPORT",
  ADD_PICTURE: "ADD_PICTURE",
  REMOVE_PICTURE: "REMOVE_PICTURE",
  UPLOAD_PICTURES: "UPLOAD_PICTURES",
  UPLOAD_PICTURE: "UPLOAD_PICTURE",
  UPLOAD_PICTURES_PROGRESS: "UPLOAD_PICTURES_PROGRESS",
  UPLOAD_PICTURES_FINISHED: "UPLOAD_PICTURES_FINISHED",
  UPLOAD_PICTURE_SUCCEEDED: "UPLOAD_PICTURE_SUCCEEDED",
  UPLOAD_PICTURE_FAILED: "UPLOAD_PICTURE_FAILED",
  UPDATE_REPORT_HOUSE_PICTURE: "UPDATE_REPORT_HOUSE_PICTURE",
  UPDATE_REPORT_EMPTY_VALUES: "UPDATE_REPORT_EMPTY_VALUES",
  APPROVE_REPORT: "APPROVE_REPORT",
  APPROVE_REPORT_SUCCEEDED: "APPROVE_REPORT_SUCCEEDED",
  APPROVE_REPORT_FAILED: "APPROVE_REPORT_FAILED",
  NEED_SAVE_REPORT: "NEED_SAVE_REPORT",
  ADD_NEW_TEMPLATE_ITEM: "ADD_NEW_TEMPLATE_ITEM",
  ADD_NEW_PARAMETER_ITEM: "ADD_NEW_PARAMETER_ITEM",
  REMOVE_PARAMETER_ITEM: "REMOVE_PARAMETER_ITEM",
  ADD_TEMPLATE_TO_OBJECT: "ADD_TEMPLATE_TO_OBJECT",
  UPDATE_OBJECT_TEMPLATE: "UPDATE_OBJECT_TEMPLATE",
  CHANGE_SECTION_HIDDEN_STATE: "CHANGE_SECTION_HIDDEN_STATE",
  UPDATE_TEMPLATE_ITEM: "UPDATE_TEMPLATE_ITEM",
  UPDATE_REPORT_MAINTENANCE_INFO: "UPDATE_REPORT_MAINTENANCE_INFO",
  ADD_DESCRIPTION: "ADD_DESCRIPTION",
  REMOVE_DESCRIPTION: "REMOVE_DESCRIPTION",
  CHANGE_DESCRIPTION: "CHANGE_DESCRIPTION",
  ADD_DESCRIPTION_PHOTO: "ADD_DESCRIPTION_PHOTO",
  REMOVE_DESCRIPTION_PHOTO: "REMOVE_DESCRIPTION_PHOTO",
  HIDE_GROUP_VALUES: "HIDE_GROUP_VALUES",
  OUT_OF_SYNC: "OUT_OF_SYNC",
  SYNCHRONIZED: "SYNCHRONIZED",
  AUTOSAVE: "AUTOSAVE",
  ADD_TO_WISHLIST: "ADD_TO_WISHLIST",
  REMOVE_FROM_WISHLIST: "REMOVE_FROM_WISHLIST",
  EVALUATE_SECTION : "EVALUATE_SECTION",
  CALCULATE_EVALUATION: "CALCULATE_EVALUATION",
  COLLAPSE_ALL: "COLLAPSE_ALL",
  UPDATE_WISHLIST_FROM_CUSTOMER: "UPDATE_WISHLIST_FROM_CUSTOMER",
  MERGED_REPORT: "MERGED_REPORT",
  ENABLE_EDITING: "ENABLE_EDITING",
  ENABLE_EDITING_SUCCEEDED: "ENABLE_EDITING_SUCCEEDED",
  ENABLE_EDITING_FAILED: "ENABLE_EDITING_FAILED",
  UPLOAD_PDF: "UPLOAD_PDF",
  UPLOAD_PDF_SUCCEEDED:"UPLOAD_PDF_SUCCEEDED",
  SET_ACTIVE_REPORT_LAYOUT: "SET_ACTIVE_REPORT_LAYOUT",
};

export const User = {
  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCEEDED: "LOGIN_SUCCEEDED",
  LOGIN_FAILED: "LOGIN_FAILED",
  CLEAR_USER_DATA: "CLEAR_USER_DATA",
  AUTH_LOCAL: "AUTH_LOCAL",
  AUTH_LOCAL_NOT_FOUND: "AUTH_LOCAL_NOT_FOUND",
  LOGOUT: "LOGOUT",
  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_SUCCEEDED: "GET_PROFILE_SUCCEEDED",
  GET_PROFILE_FAILED: "GET_PROFILE_FAILED",
  CHECK_PROFILE: "CHECK_PROFILE",
  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_SUCCEEDED: "UPDATE_PROFILE_SUCCEEDED",
  UPDATE_PROFILE_FAILED: "UPDATE_PROFILE_FAILED",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_SUCCEEDED: "CHANGE_PASSWORD_SUCCEEDED",
  CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
  GET_RATING: "GET_RATING",
  GET_RATING_SUCCEEDED: "GET_RATING_SUCCEEDED",
  GET_RATING_VALUES: "GET_RATING_VALUES",
  GET_RATING_VALUES_SUCCEEDED: "GET_RATING_VALUES_SUCCEEDED",
  LINK_REQUEST_TO_HOUSE: "LINK_REQUEST_TO_HOUSE",
  LINK_REQUEST_TO_HOUSE_SUCCEEDED: "LINK_REQUEST_TO_HOUSE_SUCCEEDED",
  GET_LINK_REQUESTS: "GET_LINK_REQUESTS",
  GET_LINK_REQUESTS_SUCCEEDED: "GET_LINK_REQUESTS_SUCCEEDED",
  GET_TEAM: "GET_TEAM",
  GET_TEAM_SUCCEEDED: "GET_TEAM_SUCCEEDED",
  MANAGE_TEAM: "MANAGE_TEAM",
  MANAGE_TEAM_SUCCEEDED: "MANAGE_TEAM_SUCCEEDED",
  GET_COMPANIES: "GET_COMPANIES",
  GET_COMPANIES_SUCCEEDED: "GET_COMPANIES_SUCCEEDED",
  NOTIFICATION_RECEIVED: "NOTIFICATION_RECEIVED",
  GET_OWN_COMPANY: "GET_OWN_COMPANY",
  GET_OWN_COMPANY_SUCCEEDED: "GET_OWN_COMPANY_SUCCEEDED",
  USER_OPERATION_FAILED: "USER_OPERATION_FAILED",
  SEND_FORGOT_PASSWORD_REQUEST: "SEND_FORGOT_PASSWORD_REQUEST",
  SEND_FORGOT_PASSWORD_REQUEST_SUCCEEDED: "SEND_FORGOT_PASSWORD_REQUEST_SUCCEEDED",
  SEND_FORGOT_PASSWORD_REQUEST_FAILED: "SEND_FORGOT_PASSWORD_REQUEST_FAILED",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCEEDED: "RESET_PASSWORD_SUCCEEDED",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
  GET_FILE: "GET_FILE",
  GET_FILE_SUCCEEDED: "GET_FILE_SUCCEEDED",
  GET_FILE_FAILED: "GET_FILE_FAILED"  
};

export const Admin = {
  //user
  GET_USER_LIST_SUCCEEDED: "GET_USER_LIST_SUCCEEDED",
  UPLOAD_USER: "UPLOAD_USER",
  UPLOAD_USER_SUCCEEDED: "UPLOAD_USER_SUCCEEDED",
  UPLOAD_USER_FAILED: "UPLOAD_USER_FAILED",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_SUCCEEDED: "DELETE_USER_SUCCEEDED",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_SUCCEEDED: "UPDATE_USER_SUCCEEDED",
  UPDATE_USER_FAILED: "UPDATE_USER_FAILED",
  LOCK_USER: "LOCK_USER",
  LOCK_USER_SUCCEEDED: "LOCK_USER_SUCCEEDED",
  LOCK_USER_FAILED: "LOCK_USER_FAILED",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_SUCCEEDED: "RESET_PASSWORD_SUCCEEDED",
  RESET_PASSWORD_FAILED: "RESET_PASSWORD_FAILED",
  //report
  UNAPPROVE_REPORT: "UNAPPROVE_REPORT",
  UNAPPROVE_REPORT_SUCCEEDED: "UNAPPROVE_REPORT_SUCCEEDED",
  UNAPPROVE_REPORT_FAILED: "UNAPPROVE_REPORT_FAILED",
  //offer
  GET_OFFERS: "GET_OFFERS",
  ASSIGN_OFFER: "ASSIGN_OFFER",
  REMOVE_OFFER: "REMOVE_OFFER",
  PREPARE_OFFER: "PREPARE_OFFER",
  //Solutions
  GET_SOLUTIONS: "GET_SOLUTIONS",
  GET_SOLUTIONS_SUCCEEDED: "GET_SOLUTIONS_SUCCEEDED",
  GET_SOLUTION: "GET_SOLUTION",
  GET_SOLUTION_SUCCEEDED: "GET_SOLUTION_SUCCEEDED",
  ADD_SOLUTION: "ADD_SOLUTION",
  ADD_SOLUTION_SUCCEEDED: "ADD_SOLUTION_SUCCEEDED",
  REMOVE_SOLUTION: "REMOVE_SOLUTION",
  REMOVE_SOLUTION_SUCCEEDED: "REMOVE_SOLUTION_SUCCEEDED",
  UPDATE_SOLUTION: "UPDATE_SOLUTION",
  UPDATE_SOLUTION_SUCCEEDED: "UPDATE_SOLUTION_SUCCEEDED",
  GET_VENDORS: "GET_VENDORS",
  GET_VENDORS_SUCCEEDED: "GET_VENDORS_SUCCEEDED",
  GET_VENDOR: "GET_VENDOR",
  GET_VENDOR_SUCCEEDED: "GET_VENDOR_SUCCEEDED",
  ADD_VENDOR: "ADD_VENDOR",
  ADD_VENDOR_SUCCEEDED: "ADD_VENDOR_SUCCEEDED",
  REMOVE_VENDOR: "REMOVE_VENDOR",
  REMOVE_VENDOR_SUCCEEDED: "REMOVE_VENDOR_SUCCEEDED",
  UPDATE_VENDOR: "UPDATE_VENDOR",
  UPDATE_VENDOR_SUCCEEDED: "UPDATE_VENDOR_SUCCEEDED",
  CLEAR_CURRENT_SOLUTION: "CLEAR_CURRENT_SOLUTION",
};

export type AppAction = {
  type: keyof typeof Common | keyof typeof Template | keyof typeof Report | keyof typeof User | keyof typeof Admin;
  payload: any;
};
