import React from "react";
import { Document, Page, View, Text } from "@react-pdf/renderer";
import styles from "../Vedligeholdelserapport/pdfDocumentStyles";
import PdfDocFooter from "./components/PdfDocFooter";
import PdfDocHeader from "./components/PdfDocHeader";
import PdfDocInside from "./components/PdfDocInside";
import PdfDocHousePicture from "./components/PdfDocHousePicture";
import PdfDocStaticText from "./components/PdfDocStaticText";
import PdfDocPostReportText from "./components/PdfDocPostReportText";
import PdfDocReportObject from "./components/PdfDocReportObject";
import PdfDocReportMaintenanceInfo from "./components/PdfDocReportMaintenanceInfo";
import PdfDocReportBudgetPerWorkers from "./components/PdfDocReportBudgetPerWorkers";
import PdfDocWishList from "./components/PdfDocWishList";
import { PdfReport } from "../PdfGenerator";
import { NIL } from "uuid";
import PdfBarometer from "../PdfBarometer";

const PdfDocPage = (props: PdfReport) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.mainWrapper}>
          <PdfDocHeader {...props.data} creationDate={props.created} reportDate={props.data.reportDate} />
          <View style={styles.column}>
            {/* PDF main part implementation */}

            {props.data?.picture ? <PdfDocHousePicture picture={props.data.picture} /> : null}
            <View style={{ ...styles.row, justifyContent: "flex-end" }}>
              <Text style={{ ...styles.title }}>Den byggesagkyndiges vurdering af ejendommes generelle stand</Text>
            </View>
            <View style={{ ...styles.row, justifyContent: "flex-end" }}>
              <PdfBarometer evaluation={props.evaluation} />
            </View>
            <View break />
            {props.data ? <PdfDocInside {...props.data} firstName={props.firstName} lastName={props.lastName} /> : null}
            <PdfDocStaticText />
            <PdfDocReportMaintenanceInfo maintenanceInfoList={props.maintenanceInfo} />
            {props.wishList?.length ? <PdfDocWishList wishList={props.wishList} /> : null}
            <PdfDocReportBudgetPerWorkers parameters={props.parameters} />
            {props.objects.map((x) => {
              return (
                <PdfDocReportObject
                  key={x.id}
                  {...x}
                  pictures={props.pictures.filter((p) => p.objectId === x.id && p.itemId === NIL)}
                  template={x.template || props.template}
                  parameters={props.parameters.filter((p) => p.objectId === x.id)}
                />
              );
            })}
          </View>
        </View>
        <View break style={styles.mainWrapper}>
          <PdfDocPostReportText />
        </View>
        <PdfDocFooter />
      </Page>
    </Document>
  );
};

export default PdfDocPage;
