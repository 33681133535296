import { useReducer, useEffect, useContext } from "react";
import {
  Grid,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputLabel,
  Typography,
  ListItemText,
  Checkbox,
  IconButton,
  SelectChangeEvent,
  Divider,
  useMediaQuery,
  Button,
  Collapse,
} from "@mui/material";
import { CraftsmanTypes, TimeEstimate } from "../../constants/appConstants";
import { NumericTextField } from "../../shared/FormattedFields";
import ImageModal from "../../shared/ImageModal";
import { useTheme } from "@mui/styles";
import {
  CraftsmanType,
  InspectorReportParameter,
  PictureRef,
  TemplateItem,
  TemplateItemType,
  TimeToFinish,
} from "../../interfaces/models";
import CloseIcon from "@mui/icons-material/Close";
import { NIL, v4 as uuidv4 } from "uuid";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useReportActions, useReportState } from "../reportActions";
import { debounce } from "lodash";
import AddNewParamPanel from "./AddNewParamPanel";
import ReportItemDescription from "./ReportItemDescription";
import ClearIcon from "@mui/icons-material/Clear";
import { ChangeContext } from "../CreateReportPage";
import { TransitionGroup } from "react-transition-group";
import ReportItemMenu from "./ReportItemMenu";
import GeneralDialog from "../../shared/GeneralDialog";
import ParameterEditDialog from "../dialogs/ParameterEditDialog";
import { ErrorStyledTooltip } from "../../shared/Tooltips";

interface ReportItemState extends InspectorReportParameter {
  priceEstimateNoVat: number;
  init: boolean;
  reportId: string;
  picShown: boolean;
  changed: boolean;
  openSelect: boolean;
  addItemShown: boolean;
  activePic?: PictureRef;
  removeDialogShown: boolean;
  editDialogShown: boolean;
}

const initialState: ReportItemState = {
  reportId: "",
  value: "",
  description: "",
  timeEstimate: TimeToFinish.None,
  note: "",
  craftsmen: [],
  picShown: false,
  changed: false,
  openSelect: false,
  id: "",
  itemId: "",
  objectId: "",
  priceEstimate: 0,
  priceEstimateNoVat: 0,
  hidden: false,
  addItemShown: false,
  init: true,
  name: "",
  pictures: [],
  label: "",
  descriptions: [],
  groupItemId: "",
  removeDialogShown: false,
  editDialogShown: false,
};

const reducer = (state: ReportItemState, action: { type: keyof ReportItemState | "reset"; payload: any }) => {
  switch (action.type) {
    case "reset":
      return {
        ...state,
        ...action.payload,
        changed: true,
      };
    default:
      return { ...state, [action.type]: action.payload, changed: true };
  }
};

type ReportItemProps = InspectorReportParameter & {
  showAddItem: boolean;
  variant: "frame" | "divider" | "default";
  sectionId: string;
  isReadOnly?: boolean;
};

const ReportItem = (props: ReportItemProps) => {
  const report = useReportState();
  const actions = useReportActions();
  const { id } = props;
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("md"));
  const [state, dispatch] = useReducer(reducer, initialState);
  const changeCtx = useContext(ChangeContext);
  const isXs = useMediaQuery(theme.breakpoints.down("sm"));

  const opts = (report.objects.find((x) => x.id === props.objectId)?.template || report.template)?.sections
    .find((x) => x.id === props.sectionId)
    ?.items.filter((x) => x.id === props.itemId)[0]?.options;

  const options = opts?.length ? opts : report.template.options;

  useEffect(() => {
    if (report.id !== state.reportId || !state.value) {
      const parameter = report.parameters.find((x) => x.id === id);
      dispatch({
        type: "reset",
        payload: {
          ...initialState,
          reportId: report.id,
          ...parameter,
          init: false,
        },
      });
    }
    return () => {
      //
    };
  }, [id, report.id, state.reportId, report.parameters, state.value]);

  //TODO: needs to be optimized because the UI is cleaned and then filled with values again (not critical but annoying)
  useEffect(() => {
    if (report.sync) {
      const parameter = report.parameters.find((x) => x.id === id);
      dispatch({
        type: "reset",
        payload: {
          reportId: report.id,
          ...parameter,
          init: false,
        },
      });
    }

    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.sync]);

  const changeStoreValue = debounce((id: string, key: string, value: any) => {
    actions.changeValue(id, key, value);
  }, 100);

  const changeValue = (key: keyof ReportItemState, value: any, local?: boolean) => {
    changeCtx.current++;
    dispatch({ type: key, payload: value });
    if (!local) {
      changeStoreValue(props.id, key as string, value);
    }
  };

  const changePriceEstimate = (value: any) => {
    changeValue("priceEstimate", value);
    dispatch({ type: "priceEstimateNoVat", payload: value - (value - value / 1.25) });
  };

  const changePriceEstimateNoVat = (value: any) => {
    const priceEstimateWithVat = value + value * 0.25;
    dispatch({ type: "priceEstimateNoVat", payload: value });
    changeValue("priceEstimate", priceEstimateWithVat);
  };

  useEffect(() => {
    if (state.priceEstimate) {
      dispatch({
        type: "priceEstimateNoVat",
        payload: state.priceEstimate - (state.priceEstimate - state.priceEstimate / 1.25),
      });
    }

    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.priceEstimate]);

  const changeMultiSelectValue = (event: SelectChangeEvent<any>, key: keyof InspectorReportParameter) => {
    changeCtx.current++;
    const value = event.target.value;
    if (value !== undefined && Array.isArray(value)) {
      const filteredValue = value.filter((x) => x !== undefined);
      dispatch({ type: key, payload: filteredValue });
      changeStoreValue(props.id, key as string, filteredValue);
    }
  };

  const setSelectOpen = () => {
    dispatch({ type: "openSelect", payload: true });
  };

  const closeSelect = () => {
    dispatch({ type: "openSelect", payload: false });
  };

  const showHousePic = (pic: PictureRef) => {
    dispatch({ type: "picShown", payload: true });
    dispatch({ type: "activePic", payload: pic });
  };

  const closePic = () => {
    dispatch({ type: "picShown", payload: false });
  };

  const removePic = () => {
    changeCtx.current++;
    dispatch({ type: "picShown", payload: false });
    actions.removePicture(state.activePic);
  };

  const toggleHidden = () => {
    changeCtx.current++;
    dispatch({ type: "hidden", payload: !state.hidden });
    actions.changeValue(props.id, "hidden", !state.hidden);
  };

  const addNewParameter = (newName: string) => {
    changeCtx.current++;

    const newParam: InspectorReportParameter = {
      hidden: false,
      id: uuidv4(),
      itemId: props.itemId,
      label: props.label,
      objectId: props.objectId,
      name: newName,
      timeEstimate: TimeToFinish.None,
      priceEstimate: 0,
      value: "",
      description: "",
      craftsmen: [],
      note: "",
      pictures: [],
      groupItemId: props.groupItemId,
      descriptions: [],
    };

    if (!props.groupItemId) {
      //now the object level to add items is a default one
      //if objects have different templates it is to be added ONLY at the object level
      let template = report.objects.find((x) => x.id === props.objectId)?.template;
      if (!template) {
        template = report.template;
      }

      const item = template.sections.find((x) => x.id === props.sectionId)?.items.find((x) => x.id === props.itemId);
      if (item) {
        const newItem: TemplateItem = {
          id: uuidv4(),
          name: props.name,
          label: "",
          options: item.options,
          parentId: props.sectionId,
          type: TemplateItemType.Item,
          groupId: item.id,
          autoHideItems: false,
        };

        actions.updateTemplateItem(props.objectId, { ...item, type: TemplateItemType.Group });
        actions.addNewTemplateItem(props.objectId, newItem);

        const { ...replaceParam } = props;

        replaceParam.id = uuidv4();
        replaceParam.priceEstimate = 0;
        replaceParam.priceEstimateNoVat = 0;
        actions.changeValue(props.id, "groupItemId", item.id);
        actions.changeValue(props.id, "itemId", newItem.id);
        actions.addNewParameter(replaceParam);
        newParam.groupItemId = item.id;
        newParam.itemId = NIL;
      }
    }

    actions.addNewParameter(newParam);

    dispatch({ type: "addItemShown", payload: false });
  };

  const cancelAddItem = () => {
    dispatch({ type: "addItemShown", payload: false });
  };

  const changeOption = (event: SelectChangeEvent<any>) => {
    changeCtx.current++;

    const value = event.target.value;
    changeValue("value", value);
    const desc = options?.find((x) => x.value === value)?.description ?? "";

    if (!props.descriptions.length) {
      actions.addDescription(props.id, {
        id: uuidv4(),
        description: desc,
        pictures: [],
      });
    } else {
      actions.changeDescription(props.id, props.descriptions[0].id, desc);
    }

    if (props.groupItemId) {
      actions.hideGroupValues(props.groupItemId, props.id);
    }
  };

  const changeDescription = debounce(
    (itemId: string, descId: string, value: string) => actions.changeDescription(itemId, descId, value),
    100
  );

  const showRemoveDialog = () => {
    dispatch({ type: "removeDialogShown", payload: true });
  };

  const removeItem = (confirm?: boolean) => {
    if (confirm) {
      actions.removeParameter(props.id);
    }
    dispatch({ type: "removeDialogShown", payload: false });
  };

  const editTitle = (value?: string) => {
    dispatch({ type: "editDialogShown", payload: false });
    if (value) {
      actions.changeValue(props.id, "name", value);
    }
  };

  return (
    <>
      <Grid
        container
        sx={{
          minHeight: "80px",
          backgroundColor: "white",
          paddingLeft: 1,
          paddingRight: 1,
          borderRadius: "4px",
          boxSizing: "border-box",
          border: props.variant === "frame" ? "2px solid grey" : "none",

          marginBottom: props.variant === "frame" ? 2 : "inherit",
        }}
      >
        <Grid item xs={5.5} sm={4} container alignItems="center">
          <Typography
            variant="body1"
            color={state.changed && !state.value ? "error" : "inherit"}
            sx={{ marginLeft: isXs ? "-5px" : "0px" }}
          >
            {!props.groupItemId && props.label} {props.name}
          </Typography>
        </Grid>

        <Grid
          item
          xs={6.5}
          sm={8}
          sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginLeft: "-5px" }}
        >
          <ErrorStyledTooltip title={!props.hidden && report.approved ? "Godkendt rapport kan ikke ændres" : ""}>
            <Grid item xs={11} sm={6}>
              <Typography variant="caption">Bygningsdelens tilstand</Typography>
              <FormControl sx={{ width: "95%" }}>
                <Select
                  disabled={state.hidden || props.isReadOnly}
                  sx={{
                    minWidth: 110,
                    position: "relative",
                    zIndex: 1,
                  }}
                  value={state.value || ""}
                  fullWidth
                  onChange={changeOption}
                  autoWidth={true}
                >
                  {!!options?.length &&
                    options.map((option) => (
                      <MenuItem key={option.id} value={option.value} id={option.id}>
                        {option.value}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </ErrorStyledTooltip>
          <Grid item container justifyContent={"flex-end"} xs={1} sm={4} marginRight={"-15px"}>
            {!props.isReadOnly && (
              <ReportItemMenu
                hidden={state.hidden}
                toggleHidden={toggleHidden}
                remove={showRemoveDialog}
                showEdit={() => dispatch({ type: "editDialogShown", payload: true })}
              />
            )}
          </Grid>
        </Grid>

        <Collapse
          in={
            report.newCustomParamIds?.some((x) => x === props.id) ||
            (!props.hidden && !!options?.filter((x) => x.value === state.value && x.worth).length)
          }
          timeout="auto"
          unmountOnExit
          sx={{
            width: "100%",
          }}
        >
          <TransitionGroup>
            {!!props.descriptions?.length &&
              props.descriptions.map((x, idx) => (
                <Collapse key={x.id}>
                  <Grid key={x.id} item container xs={12} spacing={2} sx={{ position: "relative" }}>
                    {idx !== 0 && (
                      <>
                        <Divider flexItem variant="fullWidth" sx={{ width: "100%", margin: "25px 0 0 8px" }} />
                        <IconButton
                          sx={{ position: "absolute", right: 0, top: 25 }}
                          onClick={() => actions.removeDescription(props.id, x.id)}
                          disabled={props.isReadOnly}
                        >
                          <ClearIcon fontSize="small" />
                        </IconButton>
                      </>
                    )}

                    <ReportItemDescription
                      disabled={!!props.isReadOnly}
                      showPicture={showHousePic}
                      addPicture={(pic) => actions.addDescriptionPicture(props.id, x.id, pic)}
                      changeDescription={(val) => changeDescription(props.id, x.id, val)}
                      objectId={props.objectId}
                      itemId={props.id}
                      id={x.id}
                      description={x.description}
                      pictures={x.pictures}
                    />
                  </Grid>
                </Collapse>
              ))}
          </TransitionGroup>
          {!props.isReadOnly && (
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={12}>
                <Button
                  sx={{ marginBottom: 2 }}
                  color="secondary"
                  variant="outlined"
                  onClick={() =>
                    actions.addDescription(props.id, {
                      id: uuidv4(),
                      description: "",
                      pictures: [],
                    })
                  }
                >
                  Tilføj ny beskrivelse
                </Button>
              </Grid>
            </Grid>
          )}
          {!options?.find((x) => x.value === state.value)?.uncorrectable && (
            <ErrorStyledTooltip title={!props.hidden && report.approved ? "Godkendt rapport kan ikke ændres" : ""}>
              <Grid container spacing={1}>
                <Grid item sm={4} xs={12}>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Håndværkere</InputLabel>
                    <Select
                      open={state.openSelect}
                      // MenuProps={{ classes: { list: classes.selectList } }}
                      onOpen={setSelectOpen}
                      label="Håndværkere"
                      error={!state.craftsmen?.length}
                      multiple
                      sx={{
                        minWidth: 200,
                      }}
                      value={state.craftsmen as CraftsmanType[]}
                      onChange={(event) => changeMultiSelectValue(event, "craftsmen")}
                      fullWidth
                      renderValue={(val: any) =>
                        val.reduce(
                          (acc: string, v: CraftsmanType) => (acc ? acc + ", " + CraftsmanTypes[v] : CraftsmanTypes[v]),
                          ""
                        )
                      }
                      disabled={props.isReadOnly}
                    >
                      <Grid
                        sx={{
                          position: "sticky",
                          padding: "0 10px",
                          top: 0,
                          backgroundColor: "#fff",
                          zIndex: 2,
                          borderBottom: "1px solid lightgrey",
                        }}
                        container
                        justifyContent={isSm ? "flex-start" : "flex-end"}
                      >
                        <IconButton size="large" onClick={closeSelect}>
                          {isSm ? <ArrowBackIcon /> : <CloseIcon />}
                        </IconButton>
                      </Grid>
                      {Object.entries(CraftsmanTypes)
                        .filter((x) => x[1] !== CraftsmanTypes[0])
                        .sort((a, b) => {
                          if (a[0] >= "100") return 1;
                          return a[1] > b[1] ? 1 : -1;
                        })
                        .map(([id, value]) => (
                          <MenuItem key={id} value={parseInt(id)}>
                            <Checkbox
                              color="secondary"
                              checked={
                                !!(
                                  state.craftsmen?.length &&
                                  state.craftsmen.some((x: CraftsmanType) => x === parseInt(id))
                                )
                              }
                            />
                            <ListItemText primary={value} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={2} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel>Udbedring</InputLabel>
                    <Select
                      label="Udbedring"
                      //error={!state.timeEstimate}
                      sx={{
                        minWidth: 110,
                        position: "relative",
                        zIndex: 1,
                      }}
                      value={state.timeEstimate}
                      onChange={(event) => changeValue("timeEstimate", event.target.value)}
                      disabled={props.isReadOnly}
                    >
                      {TimeEstimate.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {item.value}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item sm={3} xs={12}>
                  <NumericTextField
                    suffix=" Kr"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fullWidth
                    label="Pris overslag ekskl. moms"
                    value={state.priceEstimateNoVat > 0 ? state.priceEstimateNoVat : ""}
                    onValueChange={(values) => {
                      changePriceEstimateNoVat(values.floatValue);
                    }}
                    disabled={props.isReadOnly}
                  />
                </Grid>
                <Grid item sm={3} xs={12}>
                  <NumericTextField
                    suffix=" Kr"
                    thousandSeparator="."
                    decimalSeparator=","
                    decimalScale={2}
                    fullWidth
                    label="Pris overslag inkl. moms"
                    value={state.priceEstimate > 0 ? state.priceEstimate : ""}
                    onValueChange={(values) => {
                      changePriceEstimate(values.floatValue);
                    }}
                    disabled={props.isReadOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    label="Note/uddybende kommentarer"
                    value={state.note || ""}
                    onChange={(event) => changeValue("note", event.target.value)}
                    margin="dense"
                    multiline
                    fullWidth
                    variant="outlined"
                    disabled={props.isReadOnly}
                  />
                </Grid>
              </Grid>
            </ErrorStyledTooltip>
          )}
        </Collapse>
        {/* )} */}
        {!props.hidden && props.showAddItem && !props.isReadOnly && (
          <AddNewParamPanel
            show={state.addItemShown}
            addNewParameter={addNewParameter}
            cancelAddItem={cancelAddItem}
            onShowClick={() => dispatch({ type: "addItemShown", payload: true })}
            isReadOnly={props.isReadOnly}
          />
        )}
      </Grid>
      {props.variant === "divider" && (
        <Divider variant="fullWidth" sx={{ width: "100%", marginTop: 1, marginBottom: 1 }} />
      )}
      <ImageModal
        shown={state.picShown}
        remove={!report.loading && !props.isReadOnly ? removePic : undefined}
        handleClose={closePic}
        source={state.activePic ? state.activePic.url || state.activePic.data : ""}
      />
      <GeneralDialog
        open={state.removeDialogShown}
        dialogTitle={"Ønske du at slette dette punkt?"}
        handleClose={removeItem}
      />
      <ParameterEditDialog open={state.editDialogShown} onClose={editTitle} title={props.name} />
    </>
  );
};

//export default React.memo(ReportItem);
export default ReportItem;
