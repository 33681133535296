import React, { useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { TextFieldConnector } from "../../shared/FormConnectors";
import { useUserActions } from "../../user/userActions";

type PasswordFormData = {
  oldPassword: string;
  newPassword: string;
  confirmedPassword: string;
};

const ChangePasswordDialog = (props: { open: boolean; handleClose: (status :{ error?: string, success: boolean }) => void }) => {
  const [loading, setLoading] = useState(false);
  const userActions = useUserActions();

  const formSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Forkert kodeord"),
    newPassword: Yup.string().required().min(6, "Kodeordet skal være mindst 6 tegn langt"),

    confirmedPassword: Yup.string()
      .required("Kodeordene stemmer ikke overens")
      .oneOf([Yup.ref("newPassword")], "Kodeordene stemmer ikke overens"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<PasswordFormData>({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmedPassword: "",
    },
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });

  const updatePassword = async (data: PasswordFormData) => {
    const errorMessage = "Der skete en fejl. Prøv igen senere.";
    setLoading(true);
    try {
      await userActions.changePassword(data.oldPassword, data.newPassword);
      closeDialog({ success: true });
    } catch (error: any) {
      closeDialog({ error: errorMessage, success: false });
    } finally {
      setLoading(false);
    }
  };

  const closeDialog = (status :{ error?: string, success: boolean }) => {
    props.handleClose(status);
    reset();
  };

  return (
    <StyledDialog open={props.open} onClose={() => closeDialog({ success:  false })}>
      <DialogTitle variant="h5" align="center">
        Skift kodeord
      </DialogTitle>
      <form onSubmit={handleSubmit(updatePassword)}>
        <DialogContent>
          <Grid container spacing={2} direction={"column"}>
            <TextFieldConnector register={register("oldPassword", { required: true })}>
              <TextField
                autoComplete="new-password"
                margin="dense"
                id="oldPassword"
                label="Nuværende kodeord"
                type="password"
                error={!!errors.oldPassword}
                helperText={errors.oldPassword?.message}
              />
            </TextFieldConnector>
            <TextFieldConnector register={register("newPassword", { required: true })}>
              <TextField
                autoComplete="new-password"
                margin="dense"
                id="newPassword"
                label="Nyt kodeord"
                type="password"
                error={!!errors.newPassword}
                helperText={errors.newPassword?.message}
              />
            </TextFieldConnector>
            <TextFieldConnector register={register("confirmedPassword", { required: true })}>
              <TextField
                autoComplete="new-password"
                margin="dense"
                id="confirmedPassword"
                label="Bekræft kodeord"
                type="password"
                error={!!errors.confirmedPassword}
                helperText={errors.confirmedPassword?.message}
              />
            </TextFieldConnector>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              margin: 1,
              width: 190,
              marginRight: 0,
            }}
            color="primary"
            variant="contained"
            type="submit"
            disabled={loading}
          >
            Skift kodeord
          </Button>
          <Button
            sx={{
              margin: 1,
              width: 190,
              marginRight: 0,
            }}
            color="primary"
            variant="outlined"
            onClick={() => closeDialog({ success: false })}
            disabled={loading}
          >
            Annuller
          </Button>
        </DialogActions>
      </form>
    </StyledDialog>
  );
};

export default ChangePasswordDialog;

const StyledDialog = withStyles({
  paper: {
    padding: 30,
    justifyContent: "center",
    alignItems: "center",
  },
})(Dialog);
