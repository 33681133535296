import styles from "../styles";
import React from "react";
import { View, Text } from "@react-pdf/renderer";
import PdfReportItem from "./PdfReportItem";
import { InspectorReportParameter, PictureRef, TemplateSection } from "../../../../../interfaces/models";

const PdfReportSection = (
  props: TemplateSection & {
    objectId: string;
    parameters: InspectorReportParameter[];
    pictures: PictureRef[];
  }
) => {
  const renderReportItems = () => {
    const groups = props.parameters
      ?.filter((x) => props.items?.find((t) => t.id === x.itemId) && !x.hidden)
      .reduce((group, param) => {
        const { label } = param;
        if (label) {
          group[label] = group[label] ?? [];
          group[label].push(param);
        }
        return group;
      }, {} as { [key: string]: InspectorReportParameter[] });

    const reportItems: React.ReactNode[] = [];
    for (const key in groups) {
      const params = groups[key];
      reportItems.push(
        params.map((p, i) => {
          // if (i !== 0) {
          //   p.label = "";
          // }
          return (
            <PdfReportItem
              showLabel={i === 0}
              key={p.id}
              {...{
                ...p,
                sectionId: props.id,
              }}
              pictures={props.pictures.filter((x) => x.itemId === p.id)}
            />
          );
        })
      );
    }
    return reportItems;
  };

  return (
    <View style={styles.column}>
      <View
        style={{
          ...styles.row,
          ...styles.title5,
        }}
      >
        <Text style={{ ...styles.paddingRight, ...styles.fontFamily }}>{props.label}</Text>
        <Text style={styles.fontFamily}>{props.name}</Text>
      </View>
      {renderReportItems()}

      {/* {(props.parameters?.length ? props.parameters?.filter((x) => x.objectId === props.objectId && props.items?.find((t) => t.id === x.itemId)) : [])
       .map((item) => (
          <PdfReportItem
            key={props.objectId + item.id}
            pictures={props.pictures.filter((x) => x.itemId === item.id)}
            {...item}
          />
        ))} */}
    </View>
  );
};

export default PdfReportSection;
