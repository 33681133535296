import React from "react";
import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import styles from "./styles";
import PdfHouseData from "./components/PdfHouseData";
import PdfReportObject from "./components/PdfReportObject";
import { PdfReport } from "../PdfGenerator";

const PdfDoc = (props: PdfReport) => {
  return (
    <Document>
      <Page>
        <View style={{ ...styles.narrowPage, ...styles.row }}>
          <View style={{ ...styles.narrowCenter, ...styles.column }}>
            {props.data && <PdfHouseData {...props.data} />}
          </View>
        </View>
      </Page>
      <Page style={styles.page}>
        <View style={styles.mainWrapper}>
          <View fixed>
            <View style={styles.headerTop}>
              <Text style={{ ...styles.titleTop, ...styles.fontFamily }}>Serviceeftersyn</Text>
              <Image style={styles.imageHeader} src={require("../../../../assets/img/myhouseLogo.png")} />
            </View>
          </View>
          <View style={styles.header}>
            <Text style={{ ...styles.title, ...styles.fontFamily }}>Registrering af bygningens tilstand</Text>
          </View>
          <View style={styles.wrapper}>
            <View style={{ ...styles.table, ...styles.column }}>
              <View>
                <View style={{ ...styles.row, ...styles.tableTr }}>
                  <View style={styles.name}>
                    <Text style={{ ...styles.title5, ...styles.fontFamily }}>Bygning:</Text>
                  </View>
                  <View style={styles.caracter}>
                    <Text style={{ ...styles.title5, ...styles.fontFamily }}>Karakter:</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ ...styles.smallText, ...styles.column }}>
              {props.objects &&
                props.objects.map((object) => (
                  <View key={object.id} style={styles.row}>
                    <PdfReportObject
                      {...object}
                      template={object.template || props.template}
                      pictures={props.pictures.filter((x) => x.objectId === object.id)}
                      parameters={props.parameters.filter((x) => x.objectId === object.id)}
                    />
                  </View>
                ))}
            </View>
          </View>
        </View>
        <View fixed style={styles.footerWrapper}>
          <View style={styles.footer}>
            {props.data && (
              <>
                <View style={{ ...styles.row, ...styles.oneThird }}>
                  <Text style={{ ...styles.text, ...styles.fontFamily }}>{props.data.address},</Text>
                  <Text style={{ ...styles.text, ...styles.fontFamily }}>{props.data.postcode}</Text>
                  <Text style={{ ...styles.text, ...styles.fontFamily }}>{props.data.city}</Text>
                </View>
                <View style={{ ...styles.center, ...styles.oneThird }}>
                  <Text style={{ ...styles.fontFamily }}>{props.data.caseNumber}</Text>
                </View>
              </>
            )}
            <Text
              style={{
                ...styles.oneThird,
                ...styles.right,
                ...styles.fontFamily,
              }}
              render={({ pageNumber, totalPages }) => `Side ${pageNumber} af ${totalPages}`}
              fixed
            ></Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PdfDoc;
