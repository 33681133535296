import React, { useEffect, useState } from "react";
import { useReport } from "../reportActions";
import { useCommon } from "../../common/commonActions";
import { Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ApiService from "../../services/ApiService";
import { useUser } from "../../common/hooks";
import { NIL } from "uuid";

const ReportDeviceTransferPanel = () => {
  const user = useUser();
  const [report, actions] = useReport();
  const [common] = useCommon();
  const [loading, setLoading] = useState(false);
  const [checkResult, setCheckResult] = useState<boolean | null>(null);

  useEffect(() => {
    if (user.id != NIL && report.createdBy !== user.id) {
      return () => {
        //
      };
    }

    const intervalId = setInterval(async () => {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 seconds timeout

      const fetchData = async () => {
        try {
          const response = await ApiService.post<{ result: boolean }>(
            "/api/report/check-device",
            { reportId: report.id, deviceId: common.deviceId },
            undefined,
            controller.signal
          );
          setCheckResult(response.result);
        } finally {
          clearTimeout(timeoutId);
        }
      };

      await fetchData();
    }, 10000); // 10 seconds interval

    const closeReport = async () => {
      if (user.id !== NIL && report.createdBy === user.id && report.id !== NIL) {
        await actions.closeReport(report.id);
      }
    };

    return () => {
      clearInterval(intervalId);
      closeReport();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.id, common.deviceId]);

  const enableEditing = async () => {
    setLoading(true);
    try {
      await actions.enableEditing({ reportId: report.id, deviceId: common.deviceId });
      await actions.getReport(report.id);
      setCheckResult(true);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (checkResult && report.allowedToEdit) {
      return () => {
        //
      };
    }
    const getReport = async () => {
      if (user.id !== NIL && report.createdBy === user.id && report.id !== NIL) {
        await actions.getReport(report.id);
      }
    };

    getReport();
    return () => {
      //
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkResult]);

  return !report.approved && report.createdBy === user.id && (!report.allowedToEdit || checkResult === false) ? (
    <Grid container item sx={{ border: "1px solid #FF7C39", my: 1 }}>
      <Grid item xs={12} m={2}>
        <Typography variant="body1">
          Du kan ikke redigere denne rapport, fordi den er åbnet på en anden enhed. Tryk på "Aktiver redigering" for at
          aktivere redigering på denne enhed. Alle ikke-gemte ændringer på den anden enhed vil gå tabt efter
          overførslen.
        </Typography>
      </Grid>
      <Grid item container xs={12} justifyContent={"flex-end"} padding={1}>
        <Grid item>
          <LoadingButton loading={loading} onClick={enableEditing} variant="contained" color="primary">
            Aktiver redigering
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  ) : null;
};

export default ReportDeviceTransferPanel;
