import React, { useEffect, useState } from "react";
import { Typography, Grid, Divider, Select, MenuItem, SelectChangeEvent, FormControl, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Employee } from "../../interfaces/models";
import { useUser } from "../../common/hooks";

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: 20,
  },
  boldText: {
    fontWeight: "bold",
  },
  footer: {
    marginTop: 15,
  },
}));

const UserInfo = (props: {
  userId: string;
  employees: Employee[];
  showChange: boolean;
  changeEmployee: (employeeId: string) => void;
}) => {
  const [user, setUser] = useState<Employee | undefined>();
  const [selected, setSelected] = useState(props.userId);

  const userInfo = useUser();

  useEffect(() => {
    const employee = props.employees.find((x) => x.id === selected);
    if (employee) {
      setUser(employee);
    }

    return () => {
      //
    };
  }, [selected, props.employees]);

  const handleChangeInspector = (event: SelectChangeEvent<string>) => {
    setSelected(event.target.value);
  };

  const changeEmployee = (employeeId: string) => {
    props.changeEmployee(employeeId);
    setSelected(props.userId);
  };

  const classes = useStyles();
  return (
    <Grid container className={classes.root} justifyContent="flex-end">
      <Grid container item xs={12} sm={7} md={5} style={{ border: "1px solid #EEE", padding: 10, borderRadius: 3 }}>
        <Grid item container xs={12} justifyContent="space-between" alignContent="center">
          <Grid item alignSelf="center">
            <Typography>Sagkyndig</Typography>
          </Grid>
          <Grid item>
            <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
              <Select value={selected} onChange={handleChangeInspector} disabled={!props.showChange}>
                {props.employees.map((x) => (
                  <MenuItem key={x.id} value={x.id}>
                    {x.firstName + " " + x.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* <Typography className={classes.boldText}>
            {user.firstName} {user.lastName}
          </Typography> */}
          </Grid>
          <Grid item>
            {props.showChange && (
              <Button variant="contained" disabled={userInfo.id === selected} onClick={() => changeEmployee(selected)}>
                Ændre
              </Button>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs={6}>
          <Typography>Firma</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.boldText}>{userInfo.organization}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>Email</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.boldText}>{user?.email}</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography>Telefon</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.boldText}>{user?.phone || "Ingen"}</Typography>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.footer}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default UserInfo;

// const getUserRoleText = (role: UserRole) => {
//   switch (role) {
//     case UserRole.Root:
//     case UserRole.Administrator:
//       return "Admin";
//     case UserRole.Inspector:
//       return "Inspektør";
//     case UserRole.Craftsman:
//       return "Håndværkere";
//     case UserRole.Customer:
//       return "Kunde";
//     default:
//       return "Bruger";
//   }
// };
