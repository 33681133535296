import React from "react";
import { Snackbar, SnackbarContent, Typography } from "@mui/material";
import { styled } from "@mui/system";

const SnackbarContentStyled = styled(SnackbarContent, {
  shouldForwardProp: (propName: string) => propName !== "notificationType",
})<{
  notificationType: NotificationType;
}>(({ notificationType }) => ({
  backgroundColor:
    notificationType === "success"
      ? "green"
      : notificationType === "warning"
      ? "yellow"
      : notificationType === "error"
      ? "red"
      : undefined,
}));

export type NotificationType = "success" | "warning" | "error";

const NotificationBar = (props: {
  variant: NotificationType;
  open: boolean;
  message?: string;
  handleClose: () => void;
}) => {
  const { open, message, handleClose } = props;

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      key={`bottom-center`}
      autoHideDuration={5000}
      open={open}
      onClose={handleClose}
      ContentProps={{
        "aria-describedby": "message-id",
      }}
    >
      <SnackbarContentStyled
        notificationType={props.variant}
        message={
          <Typography variant="body1" id="message-id">
            {message}
          </Typography>
        }
      />
    </Snackbar>
  );
};

export default NotificationBar;
