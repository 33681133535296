import { NIL } from "uuid";
import { User as UserActions } from "../constants/actionTypes";
import { Company, CompanyListItem, CompanyRole, Rating, RatingValue, UserRole } from "../interfaces/models";
import { User, Team } from "../interfaces/models";

export interface UserState extends User {
  password: string;
  loading: boolean;
  authorized: boolean;
  error: boolean;
  localUser: true;
  competencies: number[];  
  validProfile: boolean;
  rating?: Rating;
  ratingValues: RatingValue[];
  team: Team;
  companyId: string;
  companies: CompanyListItem[];
  companiesCount: number;
  company: Company;
}

const initialState: UserState = {
  id: NIL,
  created: new Date(),
  modified: new Date(),
  userName: "",
  email: "",
  password: "",
  firstName: "",
  lastName: "",
  organization: "",
  phone: "",
  mobile: "",
  cvr: "",
  address: "",
  postCode: 0,
  city: "",
  travelRange: 0,
  locked: false,
  role: UserRole.None,
  accessToken: "",
  loading: false,
  authorized: false,
  error: false,
  localUser: true,
  competencies: [],
  pricePerHour: 100,
  validProfile: false,
  ratingValues: [],
  companyId: NIL,
  team: {
    managerId: NIL,
    id: NIL,
    name: "",
    members: [],
    created: new Date(),
    modified: new Date(),
  },
  companies: [],
  companiesCount: 0,
  companyRole: CompanyRole.None,
  lastLoginIp: "",
  municipalCodes: [],
  webSite: "",
  lockoutEnabled: false,
  companyName: "",
  company: {
    id: NIL,
    name: "",
    cvr: "",
    webSite: "",
    email: "",
    phone: "",
    address: "",
    postCode: 0,
    city: "",
    municipalCodes: [],
    competencies: [],
    employees: [],
    created: new Date(),
    modified: new Date(),
    isCustom: false,
    mobile: "",
    authNumber: "",
    comment: "",
    contactPerson: ""
  },
  phoneNumber: ""
};

const userReducer = (state = initialState, action: { type: keyof typeof UserActions; payload: any }) => {
  switch (action.type) {
    case UserActions.LOGIN_REQUEST:
    case UserActions.GET_PROFILE:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UserActions.LOGIN_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false,
        authorized: true,
        localUser: true,
      };
    case UserActions.LOGIN_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        authorized: false,
      };
    case UserActions.LOGOUT:
    case UserActions.CLEAR_USER_DATA:
      return {
        ...initialState,
      };
    case UserActions.AUTH_LOCAL:
      return {
        ...state,
        ...action.payload,
        localUser: true,
        authorized: true,
      };
    case UserActions.AUTH_LOCAL_NOT_FOUND:
      return {
        ...state,
        localUser: false,
      };
    case UserActions.GET_PROFILE_SUCCEEDED:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case UserActions.GET_PROFILE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UserActions.CHECK_PROFILE:
      return {
        ...state,
        validProfile: action.payload,
      };
    case UserActions.UPDATE_PROFILE_SUCCEEDED:
      return { ...state, ...action.payload };
    case UserActions.GET_RATING:
      return { ...state, loading: true };
    case UserActions.GET_RATING_SUCCEEDED:
      return { ...state, loading: false, rating: action.payload };
    case UserActions.GET_RATING_VALUES:
      return { ...state, loading: true };
    case UserActions.GET_RATING_VALUES_SUCCEEDED:
      return {
        ...state,
        loading: false,
        ratingValues: state.ratingValues.length
          ? [...state.ratingValues, ...action.payload.results]
          : action.payload.results,
      };
    case UserActions.GET_TEAM_SUCCEEDED:
      return { ...state, team: action.payload };
    case UserActions.GET_OWN_COMPANY_SUCCEEDED:
      return { ...state, company: action.payload };
    case UserActions.GET_COMPANIES_SUCCEEDED:
      return {
        ...state,
        companies: action.payload.results,
        companiesCount: action.payload.count,
      };
    default:
      return state;
  }
};

export default userReducer;
